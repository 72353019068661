<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createUser"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.users.add') }}
                        </v-btn>


                        <import-file
                            urlSubmit='media/usersFileImport'
                            :callbackOnImport="fetchUsersFinal"
                        />

                        <v-btn
                            outlined
                            class="ma-2"
                            @click="exportTo"
                        >
                            {{ $t('common.export') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.users.title') }}
                    <v-spacer/>
                    <v-spacer/>

                    <v-select
                        class="mx-2"
                        v-model="companies.selected"
                        :items="companies.list"
                        attach
                        :label="$t('admin.terms.company')"
                        item-text="attributes.name"
                        item-value="id"
                        clearable
                        single-line
                        hide-details
                    />

                    <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>

                    <v-text-field
                        class="mx-2"
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <user-create
                    v-if="dialog"
                    :dialog="dialog"
                    :item="itemSelected"
                    @update:dialog="dialog = $event"
                    @update:table="fetchUsers"
                />

                <!--                  @update:dialog="dialogPermissions = $event"-->
                <permissions-dialog
                    v-if="dialogPermissions"
                    :dialog="dialogPermissions"
                    :user="itemSelected"
                    @update:dialog="dialogPermissions = $event"
                />

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ showFirstLastPage: true,
                                     'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :server-items-length="pagination.totalItems"
                    :sort-by="sortby"
                    :multi-sort="true"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)"/>
                    </template>

                    <template #item.attributes.username="{ value }">
                        <div :inner-html.prop="value | highlight(search)"/>
                    </template>
                    <template #item.attributes.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)"/>
                    </template>
                    <template #item.attributes.lastname="{ value }">
                        <div :inner-html.prop="value | highlight(search)"/>
                    </template>
                    <template #item.attributes.email="{ value }">
                        <div :inner-html.prop="value | highlight(search)"/>
                    </template>
                    <!--                  -->
                    <template v-slot:item.attributes.created-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)"/>
                    </template>
                    <template #item.attributes.updated-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)"/>
                    </template>

                    <template #item.attributes.deleted-at="{ value }">
                        <v-icon
                            small
                            :color="!value ? 'red' : 'green'"
                        >
                            {{ !value ? 'mdi-check' : "mdi-close" }}
                        </v-icon>
                    </template>

                    <template #item.attributes.company_id="{ value }">
                        <div :inner-html.prop="companies.map[value] | highlight(search)"/>
                    </template>

                    <template #item.relationships.roles.data="{ value }">
                        {{
                            getRoles.filter(r => value.find(v => v.id == r.id)).map(r => r.attributes.display_name).join(',')
                        }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="resetPasswordItem(item)"
                                    v-on="on"
                                >
                                    mdi-lock-reset
                                </v-icon>
                            </template>

                            <span>{{ $t('admin.users.passwordReset') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="showDialogPermissions(item)"
                                    v-on="on"
                                >
                                    mdi-lock-open
                                </v-icon>
                            </template>

                            <span>{{ $t('common.permissions') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchUsers"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />

            <changePasswordDialog ref="changePass" />

        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import {mapGetters} from 'vuex'
import UserCreate from '@/components/admin/users/userCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import {debounce} from "debounce";
import PermissionsDialog from '@/components/admin/users/permissionsDialog'
import CompaniesAPIService from "@/services/CompaniesAPIService";
import changePasswordDialog from "@/components/admin/users/changePasswordDialog";

export default {
    components: {
        PermissionsDialog, UserCreate, ConfirmDialog, changePasswordDialog,
        importFile: () => import("@/components/common/ImportFile")
    },
    data: function () {
        return {
            urlMain: '/users',
            dialog: false,
            dialogPermissions: false,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.users.username'),
                    value: 'attributes.username'
                },
                {
                    text: this.$t('admin.users.firstname'),
                    value: 'attributes.name'
                },
                {
                    text: this.$t('admin.users.lastname'),
                    value: 'attributes.lastname'
                },
                {
                    text: this.$t('admin.users.email'),
                    value: 'attributes.email'
                },
                {
                    text: this.$t('admin.users.enable'),
                    value: 'attributes.deleted-at',
                    sortable: false
                },
                {
                    text: this.$t('admin.terms.company'),
                    value: 'attributes.company_id'
                },
                {
                    text: this.$t('admin.users.roles'),
                    value: 'relationships.roles.data'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'attributes.created-at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'attributes.updated-at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemsRoles: [],
            itemSelected: null,
            sortby: ['id'],
            pagination: {
                totalItems: 10
            },
            options: {
                itemsPerPage: 10
            },
            isValid: true,
            serverCheck: false,

            // for manage the groups
            groups: [], // all groups
            groupsSelected: [],
            dialogGroups: false,
            companies: {
                selected: null,
                list: [],
                map: {}
            }
        }
    },
    watch: {
        options: {
            handler(newValue, oldValue) {
                this.fetchUsers(newValue)

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'))
                }
            }
        },
        search: {
            handler(newValue) {
                this.options.page = 1;
                this.fetchUsers();
            }
        },
        'companies.selected': {
            handler(newValue) {
                this.options.page = 1;
                this.fetchUsers();
            }
        },
        dialog(val) {
            val || this.close()
        }
    },
    computed: {
        ...mapGetters('permissions', ['getRoles'])
    },
    mounted() {
        this.fetchCompanies();
    },
    // permissions/
    methods: {
        async exportTo() {
            try {
                const res = await axios
                    .get('media/usersFileExport', {responseType: 'arraybuffer'});

                const blob = new Blob([res.data], {type: 'application/*'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Usuarios Excel.xlsx'
                link._target = 'blank'
                link.click();
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        fetchUsers: debounce(function (e) {
            this.fetchUsersFinal(e);
        }, 500),

        async fetchUsersFinal(data = this.options) {
            /* console.log('********** data ******')
            console.log(data) */
            this.$store.dispatch('loading/start');

            const optionalsParams = {};
            optionalsParams['filter[withTrashed]'] = '1';

            if (this.search) {
                optionalsParams['filter[search]'] = this.search
            }

            if (this.companies.selected) {
                optionalsParams['filter[company]'] = this.companies.selected
            }

            if (data?.sortBy?.length) {
                const joinSort = data.sortBy.map((value, index) =>
                    (data.sortDesc[index] ? '-' : "") + value.replace('attributes.', "")
                );

                optionalsParams.sort = joinSort.join(',')
            }

            try {
                const users = await axios
                    .get(this.urlMain + '?include=roles', {
                        params: {
                            'page[size]': data.itemsPerPage,
                            'page[number]': data.page,
                            ...optionalsParams
                        }
                    })
                /* console.log('*********** users **********')
                console.log(users) */
                this.initialize(users.data);

                this.$store.dispatch('loading/end');
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.data

            this.pagination.totalItems = $data?.meta?.page?.total ?? 0
        },
        createUser() {
            this.dialog = true;
        },
        /* setRole($data) {
            this.itemsRoles = $data;
        }, */
        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchUsers();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async resetPasswordItem(item) {
            const email = item.attributes.email;
            const id = item.id;
            await this.$refs.changePass.open( {id, email} )
        },
        async showDialogPermissions(item) {
            this.itemSelected = item;
            this.dialogPermissions = true;
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        async fetchCompanies() {
            try {
                const result = await CompaniesAPIService.getCompanies({signal: ''});

                this.companies.list = result.data.data;

                const map = {};
                result.data.data.forEach(c => {
                    map[c.id] = c.attributes.name;
                });
                this.companies.map = map;

            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>
