<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex,}"
        @keydown.esc="cancel"
        @keydown.enter.stop=""
    >
        <v-card v-if="user">
            <!--          :color="options.color"-->
            <v-card-title class="text-h5">
                {{ $t('users.forgot.changePass') }}
            </v-card-title>

            <v-card-text
                class="pa-4 pt-1 black--text"

            >
                <div class="mb-6 d-flex flex-wrap align-center flex-column">
                    <span>Id:  <strong v-text="user.id"></strong></span>
                    <span>Email: <strong v-text="user.email"></strong></span>
                </div>

                <v-form
                    v-model="isValid"
                >
                    <div class="d-flex flex-wrap justify-center">
                        <v-text-field
                            v-model="ePassword"
                            solo
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :label="$t('users.forgot.passwordNew')"
                            :rules="[rules.validPassword]"
                            @click:append="showIcon = !showIcon"
                            :error-messages="serverError"
                            @keyup.prevent="serverError = ''"
                            @keydown.enter="changePassword"
                        />
                        <v-btn
                            v-if="!options.noOk"
                            color="primary"
                            class="text-body-2 font-weight-bold"
                            outlined
                            @click.native="changePassword"
                            :disabled="!isValid"
                        >
                            {{ $t('users.forgot.changePass') }}
                        </v-btn>
                    </div>
                </v-form>
                <v-divider class="my-8"/>

                <div class="d-flex flex-wrap justify-center">
                    <span style="text-align: center;">{{ $t('users.forgot.confirmResetPassword', {email: user.email}) }}</span>
                    <v-btn
                        v-if="!options.noOk"
                        color="primary"
                        class="text-body-2 font-weight-bold mt-4"
                        outlined
                        @click.native="sendEmailForChangePassword"
                    >
                        {{ $t('users.forgot.sendEmail') }}
                    </v-btn>
                </div>

            </v-card-text>

            <v-card-actions class="pt-3">
                <v-spacer />
                <v-btn
                    v-if="!options.noCancel"
                    color="primary"
                    text
                    class="text-body-2 font-weight-bold"
                    @click.native="cancel"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import rules from '@/helpers/rulesValidate'
import axios from "@/plugins/axios";

export default {
    name: "changePasswordDialog",
    data() {
        return {
            isValid: false,
            dialog: false,
            resolve: null,
            reject: null,
            options: {
                color: "primary",
                width: 400,
                zIndex: 200,
                noCancel: false,
                noOk: false
            },
            user: null,
            ePassword: null,
            showIcon: false,
            serverError: '',
            rules: {
                ...rules
            }

        };
    },

    methods: {
        open(user, options = null) {
            this.user = user;
            /* const width = this.$vuetify.breakpoint.width;
            this.options.width = width > 400 ? 400 : width - 10; */
            // console.log('width ', width)

            this.showIcon = false;
            this.dialog = true;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async sendEmailForChangePassword(){
            try {
                const email = this.user?.email;
                if (email) {
                    const result = await axios
                        .post("/recoveryPassword", {
                            email
                        });

                    if (result.status === 202) {
                        this.$store.dispatch('snackbar/success', this.$t('users.forgot.emailSended'));
                    }
                }

                this.agree();
            } catch (e) {
            }
        },
        async changePassword(){
            this.$store.dispatch('loading/start')

            try {
                const password = this.ePassword;
                const userId = this.user.id;

                const result = await axios
                    .post("/changePasswordForced", {
                        password,
                        userId
                    });

                this.$store.dispatch('loading/end');

                if (result.status === 200) {
                    this.$store.dispatch('snackbar/success', this.$t('users.profile.tab.password.passwordChanged'));

                    this.agree();
                }
            } catch (error) {
                this.$store.dispatch('loading/end');

                if (error.response.status === 422) {
                    let errors = error?.response?.data ? JSON.parse(error.response.data): {};
                    const passwordErrors = errors?.password;

                    if (passwordErrors?.length) {
                        this.serverError = passwordErrors[0];
                    }else{
                        this.serverError = "Ocurrieron problemas"
                    }
                }
            }

        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
};
</script>
<style>
.v-dialog.v-dialog--active{
 border-radius: 8px;
}
</style>
